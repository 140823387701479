/* ex)

primary: {
  default: 'red.500',
  _dark: 'red.400',
},
secondary: {
  default: 'red.800',
  _dark: 'red.700',
},

*/

export default {};
